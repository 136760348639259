
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./JoshSperling.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import EROPhoto1 from "../../../../res/Photos site/ERO/ero.png"
import EROPhoto2 from "../../../../res/Photos site/ERO/People-Magazine-comes-to-the-FUN-1983.-Photo-courtesy-of-Patti-Astor.-759x1024.jpg"
import EROPhoto3 from "../../../../res/Photos site/ERO/3.jpg"
import EROPhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Ero - Untitled - 1984.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "don-leicht",
  name: "DON LEICHT",
  description: "Josh Sperling (né en 1984 à Oneonta, New York) s'inscrit dans la tradition de la peinture minimaliste des années 1960 et 1970 et des « shaped canvas ». Il a recours à des découpes complexes de contreplaqués sur lesquels une toile est tendue puis peinte avec une palette de couleurs saturées, qui parfois détonnent. Son travail tridimensionnel tend à abolir les frontières entre la peinture et la sculpture, l'image et l'objet. Puisant ses références aussi bien dans le design que l'histoire de l'art, Sperling a conçu un langage visuel unique reconnaissable par son expressionnisme et son incontrôlable énergie. À la frontière entre la peinture et la sculpture, la démarche de Josh Sperling repose sur l'exploration de formes dynamiques assemblées entre elles. Le résultat rappelle d'ailleurs le jeu de squiggle inventé par le psychanalyste Donald Winnicott, à destination des plus jeunes et pratiqué en thérapie afin d'établir un lien entre l'enfant et son thérapeute. En reprenant le « less is more » des oeuvres minimalistes des années 1960 aux États-Unis, la volonté de Sperling est de mettre en volume la peinture, et d'exploiter son travail comme élément architectural, en créant un nouvel équilibre visuel avec les formes et les couleurs. Ces assemblages abstraits n'en sont pas moins titrés mais l'artiste s'éloigne de tout désir de représentation en incitant le spectateur à faire fonctionner son imaginaire à des fins d'interprétation Josh Sperling est présent dans la collection Charles Riva et a été exposé à Paris, Shanghai, Hong Kong et New York.",
  pdp: EROPhoto1,
  alt_pdp: "Photo de profil de Don Leicht.",
  photos: [
    { src: EROPhoto1, name: "ERO" },
    { src: EROPhoto2, name: "ERO" },
    { src: EROPhoto3, name: "ERO" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: EROPhoto4, name: "ERO", artist:"ERO\n(Dominique Philbert dit) (1967 - 2011)", title:"Untilted, 1984", desc3: "120 x 254 cm", desc4:" 47 1/4 x 100 in.", desc7: "- Collection particulière." },
  ],
};

const JoshSperling = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>JOSH SPERLING<h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1984</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default JoshSperling;