// extracted by mini-css-extract-plugin
export var ArtistDescription = "JoshSperling-module--ArtistDescription--vJKM5";
export var ArtistInfos = "JoshSperling-module--ArtistInfos--E7dq3";
export var ButtonWrapper = "JoshSperling-module--ButtonWrapper --E2S4B";
export var CardWrapper = "JoshSperling-module--CardWrapper--pfEQG";
export var CarrouselWrapper2 = "JoshSperling-module--CarrouselWrapper2--JXKPT";
export var Citations = "JoshSperling-module--Citations--e06AB";
export var DescriptionWrapper = "JoshSperling-module--DescriptionWrapper--L8T0n";
export var ImageWrapper = "JoshSperling-module--ImageWrapper--FsVNe";
export var LinkWrapper = "JoshSperling-module--LinkWrapper--0vUcE";
export var MobileProtrait = "JoshSperling-module--MobileProtrait--NIG-g";
export var More = "JoshSperling-module--More--p8CZ3";
export var MoreButton = "JoshSperling-module--MoreButton--gc-mk";
export var NameWrapper = "JoshSperling-module--NameWrapper--aeXhq";
export var PdpWrapper = "JoshSperling-module--PdpWrapper--vpieF";
export var PhotosWrapper = "JoshSperling-module--PhotosWrapper--11iEy";
export var ProfilWrapper = "JoshSperling-module--ProfilWrapper--xVNWm";
export var TitleWrapper = "JoshSperling-module--TitleWrapper--5Z-at";
export var Wrapper = "JoshSperling-module--Wrapper--Bf9it";